import "../styles/Navbar.css";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../src/assets/images";
import logo from "../assets/images/Innovat8-.svg";
import mendix from "../assets/images/mendix-logo.png";
import aws from "../assets/images/aws-logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbarcss navbar-light text-light">
        <img className="background-icon" src={images.icon33} />
        <div className="container py-2 con-css">
          <Link className="navbar-brand" to="/">
            <img src={logo} style={{ width: 200 }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse align-middle" id="navbarNav">
            <ul className="navbar-nav ms-auto nav_ul align-items-center">
              <li className="nav-item">
                <Link className="nav-link " to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ left: -100 }}
                >
                  <li>
                    <a className="dropdown-item" href="/">
                      Enterprise Mobile App Development
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Enterprise Web App Development
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Data Analytics
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Insights & Intelligence
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      AI/ML Solutions
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      DevSecOps
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Analysis of Alternatives
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Technology Assessment Services
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Full Stack Development
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Staff Augmentation Services
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ left: -120 }}
                >
                  <li>
                    <a className="dropdown-item" href="/">
                      Jumping Course
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Corrosion Inspection Management
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Laboratory Information Management System
                    </a>
                  </li>
                </ul>
              </li>
              <div className="menu-par">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Industries
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu">
                    <div className="dropmenu-flex">
                      <div>
                        <h3>
                          Industry Specific <br />
                          Expert-Driven <br />
                          Solutions.
                        </h3>
                        <p>
                          Domain-specific AI and analytics-driven technology
                          solutions to help you achieve your industry-specific
                          goals.
                        </p>
                      </div>
                      <div>
                        <h3>Financial Institution</h3>
                        <li>
                          <a className="dropdown-item" href="/">
                            Banking
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Financial Services
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Insurance
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Asset & Wealth Management Firms
                          </a>
                        </li>
                      </div>
                      <div>
                        <h3>Corporates</h3>
                        <li>
                          <a className="dropdown-item" href="/">
                            Technology & Telecom
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Life Sciences & Healthcare
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Manufacturing & Industrial Goods
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Energy
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Chemicals
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            CPG & Retail
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Logistics & Transportation
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Public Sector
                          </a>
                        </li>
                      </div>
                      <div>
                        <h3>Professional Services Firms</h3>
                        <li>
                          <a className="dropdown-item" href="/">
                            Consultancy & Advisory Firms
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Legal Firms
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Real Estate Firms
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/">
                            Education & EdTech Firms
                          </a>
                        </li>
                      </div>
                    </div>
                    <div className="industries-all">
                      <button className="industries-button">
                        All Industries
                        <img className="Industries-icon" src={images.i22} />
                      </button>
                    </div>
                  </ul>
                </li>
              </div>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Partners
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ left: -309, height: 300 }}
                >
                  <h3>Thriving Partnerships</h3>
                  <div className="dp-part">
                    <li>
                      <img
                        src={mendix}
                        className="dropdown-item"
                        style={{ width: 254, height: 100 }}
                        href="/"
                      />
                    </li>
                    <li>
                      <img
                        src={aws}
                        className="dropdown-item"
                        style={{ width: 250, height: 150 }}
                        href="/"
                      />
                    </li>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Resources
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ left: -240, width: 450, padding: "20px" }}
                >
                  <div className="dp-menu2">
                    <div>
                      <h3>
                        Enhance Your <br />
                        Knowledge of AI-Driven Low-Code <br />
                        and No-Code Technology.
                      </h3>
                      <p>
                        Discover valuable insights and experiences to strengthen
                        your business.
                      </p>
                    </div>
                    <div>
                      <h3>Resources</h3>
                      <li>
                        <a className="dropdown-item" href="/">
                          Blog
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Industry Insights
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Podcast
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Ask an Expert
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </li>
              <div
                className="nav-item1"
                style={{ marginLeft: 10, marginBottom: 2 }}
              >
                <text>lets Innovat8</text>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
