import React from 'react';

function Profile() {
  return (
    <div>
      <h2>Profile Page</h2>
    </div>
  );
}

export default Profile;
