import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "../../styles/MiddleHeader.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from "../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faArrowAltCircleLeft,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const HeaderPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const sliderRef = useRef(null);
  const slides = [
    {
      title: "AI/ML",
      description:
        "Enhance the capabilities of AI/ML with ease through Inov8, your indispensable partner in accelerating the development and deployment of cutting-edge AI/ML solutions. Inov8 empowers businesses to spearhead technological progress by enabling quick adaptations and innovations to navigate the ever-evolving AI/ML domain proficiently.",
    },
    {
      title: "Enterprise App Development",
      description:
        "Our expertise lies in developing enterprise-level applications utilizing low code approaches. Our team helps streamline development with transparency, agility, and a commitment to your goals.",
    },
    {
      title: "Strategic Advisory",
      description:
        "Inovat8 offers specialized advisory services for companies looking to implement or enhance low-code/no-code platforms. We ensure a tailored strategy that aligns with your business objectives. For more complex needs, our AI-driven full-stack development team integrates advanced solutions, utilizing AI to optimize functionality, scalability, automation, and big data management. Additionally, we provide expertise in analytics, enabling businesses to harness the power of data for smarter decision-making. Our intelligent and adaptive services are designed to grow and evolve with your business.",
    },
    {
      title: "Growth and Scale",
      description:
        "Our Growth and Scale offerings empower businesses at every stage, from initial adoption of low-code/no-code platforms to advanced AI-driven full-stack application development. We provide the necessary support and expertise to facilitate seamless transitions and optimize performance, ensuring your organization can effectively harness the potential of modern technology for sustained growth.",
    },
  ];

  const [slidesState, setSlidesState] = useState([...slides]); // Copy of the original slides

  const updateSliderPosition = () => {
    if (sliderRef.current) {
      // Move the slider to the first slide in the updated array
      sliderRef.current.style.transition = "transform 0.5s ease-in-out";
      sliderRef.current.style.transform = `translateX(0px)`; // Reset to the first position
    }
  };

  // Handle the next slide logic (remove first slide, add it to the end)
  const nextSlide = () => {
    const newSlides = [...slidesState];
    const firstSlide = newSlides.shift(); // Remove the first slide
    newSlides.push(firstSlide); // Add it to the end
    setSlidesState(newSlides); // Update the state
    updateSliderPosition(); // Update the slider position
  };

  // Handle the previous slide logic (remove last slide, add it to the beginning)
  const prevSlide = () => {
    const newSlides = [...slidesState];
    const lastSlide = newSlides.pop(); // Remove the last slide
    newSlides.unshift(lastSlide); // Add it to the beginning
    setSlidesState(newSlides); // Update the state
    updateSliderPosition(); // Update the slider position
  };

  useEffect(() => {
    updateSliderPosition(); // Make sure the slider starts in the right position
  }, [slidesState]);
  const { ref: trustedByRef, inView: trustedByInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: whatWeOfferRef, inView: whatWeOfferInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return (
    <div>
      {/* Trusted By Section */}
      <section
        className={`trusted-by-section ${
          trustedByInView ? "slide-in" : "hidden"
        }`}
        ref={trustedByRef}
      >
        <div className="trusted-by-title">
          <h2>— Trusted By</h2>
        </div>
        <div className="trusted-logos-ticker">
          <div className="trusted-logos-ticker">
            <div className="trusted-logos-wrapper">
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon1}
                alt="Ufone Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon2}
                alt="Urban Point Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon3}
                alt="Ericsson Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon4}
                alt="Nokia Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon5}
                alt="Zong Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon6}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon7}
                alt="ZTE Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon8}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon9}
                alt="ZTE Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon10}
                alt="Zong Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon11}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon12}
                alt="ZTE Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon13}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon14}
                alt="ZTE Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon1}
                alt="Ufone Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon2}
                alt="Urban Point Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon3}
                alt="Ericsson Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon4}
                alt="Nokia Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon5}
                alt="Zong Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon6}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon7}
                alt="ZTE Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon8}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon9}
                alt="ZTE Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon10}
                alt="Zong Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon11}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon12}
                alt="ZTE Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon13}
                alt="Huawei Logo"
              />
              <img
                className={`trusted-by-section ${
                  trustedByInView ? "slide-in" : "hidden"
                }`}
                src={images.icon14}
                alt="ZTE Logo"
              />
            </div>
          </div>
        </div>
      </section>
      {/* What We Offer Section */}
      <section
        className={`innovation-section ${
          whatWeOfferInView ? "slide-in" : "hidden"
        }`}
        ref={whatWeOfferRef}
      >
        <div className="innovation-main">
          <div className="innovation-offer">
            <div className="offer-text">
              <h5>
                Global Center of <span>INNOVATION</span>
              </h5>
              <h5>
                — OUR SERVICES<h2>What We Offer</h2>
              </h5>
              <p>
                Our services prioritize strategy and
                <br />
                transparency as fundamental elements. Our
                <br />
                objective is to enable you to expand your
                <br />
                business by eliminating technological
                <br />
                obstacles and providing comprehensive
                <br />
                support throughout the project's lifecycle.
              </p>
              <div className="div-services">
                <a href="#" className="view-services">
                  <p>
                    View All<span>Services</span>
                  </p>
                </a>
                <img
                  src={images.i22}
                  style={{
                    width: "30px",
                    height: "30px",
                    // marginTop: "7px",
                    marginLeft: "5px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="innovation-slide">
            <div className="Slider-container">
              {/* Navigation Buttons */}
              <div className="control-button">
                <FontAwesomeIcon
                  className="control prev"
                  onClick={prevSlide}
                  color="#fff"
                  size={"2xl"}
                  icon={faArrowCircleLeft}
                />
                <FontAwesomeIcon
                  size={"2xl"}
                  className="control next"
                  onClick={nextSlide}
                  color="#fff"
                  icon={faArrowCircleRight}
                />
              </div>

              {/* Slider Container */}
              <div
                style={{
                  flex: 0.4,
                  overflow: "hidden",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Slides */}
                <div
                  className="slider"
                  ref={sliderRef}
                  style={{
                    display: "flex",
                    transition: "transform 0.5s ease-in-out",
                    width: `${slidesState.length * 100}%`, // Total width is the number of slides
                  }}
                >
                  {slidesState.map((slide, index) => (
                    <div
                      className="slide"
                      key={index}
                      style={{
                        flex: index === 0 ? "0 0 80%" : "0 0 20%", // First item is 80%, others are 20%
                        width: index === 0 ? "80%" : "20%",
                      }}
                    >
                      <h3>{slide.title}</h3>
                      <p>{slide.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HeaderPage;
