// Import all images from the images folder
import icon1 from "./1.png";
import icon33 from "./111.png";
import TopBackground from "./TopBackground.png";
import icon2 from "./2.png";
import icon3 from "./3.png";
import icon4 from "./4.png";
import icon5 from "./5.png";
import icon6 from "./6.png";
import icon7 from "./7.png";
import icon8 from "./8.png";
import icon9 from "./9.png";
import icon10 from "./10.png";
import icon11 from "./11.png";
import icon12 from "./12.png";
import icon13 from "./13.png";
import icon14 from "./14.png";
import people from "./people.png";
import phone from "./phone.png";
import plane from "./plane.png";
import lightbulb1 from "./lightbulb-on.png";
import lightbulb2 from "./world.png";
import lightbulb3 from "./world.png";
import lightbulb4 from "./typing.png";
import i15 from "./15.png";
import i16 from "./16.png";
import i17 from "./17.png";
import i18 from "./18.png";
import i19 from "./19.png";
import i20 from "./20.png";
import i21 from "./21.png";
import i22 from "./22.png";
import i30 from "./30.png";
import i23 from "./23.png";
import i24 from "./24.png";
import i25 from "./25.png";
import i26 from "./26.png";
import i27 from "./27.png";
import i28 from "./28.png";
import i29 from "./29.png";
import i31 from "./31.png";
import i32 from "./32.png";
import i33 from "./33.png";
import i34 from "./34.png";
import i35 from "./35.png";
import i36 from "./36.png";
import i37 from "./37.png";
import i38 from "./38.png";
import i39 from "./39.png";
import i42 from "./42.png";

// Export images as an object
export default {
  i15,
  i16,
  icon33,
  i17,
  i18,
  i19,
  i20,
  i21,
  i22,
  i30,
  i23,
  i24,
  i25,
  i26,
  i27,
  i28,
  i29,
  i31,
  i32,
  i33,
  i34,
  i35,
  i36,
  i37,
  i38,
  i39,
  i42,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  icon12,
  icon13,
  icon14,
  people,
  phone,
  plane,
  lightbulb1,
  lightbulb2,
  lightbulb3,
  lightbulb4,
  TopBackground,
};
